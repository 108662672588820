import { Avatar, Box, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect } from "react";
import { HeaderText } from "../../components/HeaderText/HeaderText";
import { Column } from "../../components/Section/column";
import { Divider } from "../../components/Section/divider";
import { Row } from "../../components/Section/row";
import { Section } from "../../components/Section/section";

import { AboutUs } from "../../components/About-Us/about-us-vision-cards";
import "./style.scss";
export function TeamMember({
	firstName,
	lastName,
	position,
	imageSrc,
	email,
	degree,
}: {
	firstName: string;
	lastName: string;
	position: string;
	imageSrc: string;
	email?: string;
	degree?: string;
}) {
	return (
		<Box
			className="animateOnScroll fademe"
			sx={{
				width: "350px",
				// height: "130px",
				position: "relative",
				display: "flex",
				flexDirection: "column",
				// gap: "10px",
				alignItems: "center",
				"&:hover .avatar": {
					filter: "saturate(1)",
				},
			}}
		>
			<Avatar
				className="avatar"
				sx={{
					width: "50px",
					height: "50px",
					transition: "all .3s cubic-bezier(0.4, 0, 0.2, 1)",
					filter: "saturate(0)",
					background:
						"conic-gradient(at 8% 7%, rgb(210 85 2 / 92%) 10%, rgb(122 57 0) 141%)",
				}}
			>
				{firstName[0]}
				{lastName[0]}
			</Avatar>
			{/* <Box
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			> */}
			<Typography
				variant="h5"
				sx={{ fontWeight: "bold" }}
			>{`${firstName} ${lastName}`}</Typography>
			<Typography variant="body1" sx={{ fontWeight: "bold" }}>
				{position}
			</Typography>
			<Typography variant="body1">{degree}</Typography>

			<Typography variant="body1">{email}</Typography>
			{/* </Box> */}
		</Box>
	);
}

// export function TeamMember({
// 	firstName,
// 	lastName,
// 	position,
// 	imageSrc,
// }: {
// 	firstName: string;
// 	lastName: string;
// 	position: string;
// 	imageSrc: string;
// }) {
// 	return (
// 		<Box
// 			className="animateOnScroll fademe"
// 			sx={{
// 				position: "relative",
// 				width: "250px",
// 				height: "375px",

// 				"&:hover": {
// 					"& .TeamMemberImage": {
// 						filter: "saturate(1)",
// 						boxShadow: "0px 2px 6px 0px #00000047",
// 					},
// 				},
// 			}}
// 		>
// 			<Paper
// 				className="TeamMemberImage"
// 				sx={{
// 					// width: "250px",
// 					borderRadius: 0,
// 					display: "flex",
// 					flexDirection: "column",
// 					alignItems: "center",
// 					background: "transparent",
// 					padding: "0",
// 					margin: "0",
// 					overflow: "hidden",
// 					flexGrow: 0,

// 					filter: "saturate(0)",
// 					transition:
// 						"filter 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
// 					boxShadow: "0px 0px 0px 0px #00000047",
// 					color: "black",
// 				}}
// 			>
// 				<Avatar
// 					sx={{
// 						width: "100%",
// 						height: "auto",
// 						aspectRatio: "2/3",
// 					}}
// 					alt="Cindy Baker"
// 					src={imageSrc}
// 					variant="square"
// 				/>
// 			</Paper>
// 			<Box
// 				sx={{
// 					position: "absolute",
// 					bottom: 0,
// 					width: "100%",
// 					textAlign: "left",
// 					padding: "5px 15px",
// 					color: "white",
// 					filter: "drop-shadow(1px 1px 3px black)",
// 					background:
// 						"linear-gradient(360deg, #00000052, transparent)",
// 				}}
// 			>
// 				<Typography
// 					variant="h4"
// 					sx={{
// 						whiteSpace: "break-spaces",
// 						fontWeight: "bold",
// 						lineHeight: "1",
// 					}}
// 				>{`${firstName}\n${lastName}`}</Typography>
// 				<Typography variant="h6" sx={{ lineHeight: "1.2" }}>
// 					Chief Executive Officer Officer Officer
// 				</Typography>
// 			</Box>
// 		</Box>
// 	);
// }

export function AboutUsPage() {
	useEffect(() => {
		document.title = "Fursan - About Us";
	}, []);

	return (
		<Box
			sx={{
				minHeight: "100vh",
				background:
					"conic-gradient(at 0% 100%,  rgba(0, 0, 0, 30%) -15%,white 30%)",

				// "& .SectionBox": {
				// 	// padding: 0,
				// 	minHeight: 500,
				// },
			}}
		>
			<Container maxWidth={"xl"}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						pt: " 100px",
					}}
				>
					<HeaderText
						title=""
						phrases={[
							"Your Partner in Healthcare Innovation",
							"Fursan For Medical Supplies",
						]}
					/>
				</Box>
				<Section titlePrefix="About Us" title="">
					<Row
						sx={{
							alignItems: "center",
							flexDirection: { xs: "row-reverse", md: "unset" },
							justifyContent: "center",
							flexWrap: { xs: "wrap", md: "nowrap" },
							margin: "16px 0",

							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								// maxWidth: { md: "50%", xs: "unset" },
								// maxHeight: "400px",
							},
						}}
					>
						{/*

						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="https://www.arlingtontx.com/wp-content/uploads/2020/03/DOCTORS-edit.jpeg"
						></Paper>

							*/}
						<Column
							sx={{
								gap: "20px",
							}}
						>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								As a leading provider of medical supplies, we
								specialize in medical laboratory solutions, IVF,
								Genomics and Oncology sectors with top-quality
								products. Founded in Jordan in 1996, we have
								grown to become an industry leader, driven by
								our unwavering commitment to excellence and
								quality.
							</Typography>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								In 2012, we expanded our operations into Iraq,
								establishing subsidiaries in Baghdad and
								Kurdistan (Erbil) to better serve the region
								with medical equipment and supplies. We take
								pride in delivering the highest quality products
								and services to our customers. Our team
								comprises dedicated professionals experienced in
								the medical field, engineers, and technicians,
								all passionate about providing the best possible
								solutions and services to our clients.
							</Typography>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								At our company, we believe our customers are the
								backbone of our business. We are committed to
								building long-lasting relationships by working
								closely with our clients to understand their
								unique needs and requirements. This approach
								enables us to offer customized solutions
								tailored to their specific needs.
							</Typography>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Innovation and continuous improvement are at the
								heart of our operations. We stay abreast of the
								latest industry trends and technologies,
								allowing us to offer cutting-edge solutions that
								help our clients stay ahead of the competition.
							</Typography>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								We are also deeply committed to giving back to
								the community. We collaborate with various
								organizations dedicated to improving the health
								and well-being of people in our community and
								the region.
							</Typography>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Thank you for considering our company for your
								medical supply needs. We look forward to serving
								you and providing the best possible solutions
								for your healthcare requirements.
							</Typography>
							{/* <Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Thank you for considering our company for your
								medical supply needs. We look forward to the
								opportunity to serve you and provide you with
								the best possible solutions for your healthcare
								needs.
							</Typography> */}
						</Column>
					</Row>
				</Section>

				<Divider className="animateOnScroll fademe" />
				<Section>
					{/* <Row
						sx={{
							alignItems: "center",
							flexDirection: { xs: "row-reverse", md: "unset" },
							justifyContent: "center",
							flexWrap: { xs: "wrap", md: "nowrap" },
							margin: "16px 0",

							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
								// maxHeight: "400px",
							},
						}}
					>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="https://www.arlingtontx.com/wp-content/uploads/2020/03/DOCTORS-edit.jpeg"
						></Paper>

						<Column
							sx={{
								gap: "20px",
							}}
						>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Commitment to innovation and continuous
								improvement. Stay up-to-date with the latest
								trends and technologies in the industry.
							</Typography>
						</Column>
					</Row> */}

					<AboutUs />
				</Section>

				{/* <Divider className="animateOnScroll fademe" /> */}
				{/* <Section titlePrefix="Our" title="Mission">
					<Row
						sx={{
							alignItems: "center",
							justifyContent: "center",
							margin: "16px 0",
							flexDirection: { xs: "column-reverse", md: "row" },
							flexWrap: { xs: "wrap", md: "nowrap" },
							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
							},
						}}
					>
						<Column
							sx={{
								gap: "20px",
							}}
						>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Offer cutting-edge solutions that help our
								clients stay ahead of the competition.
							</Typography>
						</Column>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="https://www.arlingtontx.com/wp-content/uploads/2020/03/DOCTORS-edit.jpeg"
						></Paper>
					</Row>
				</Section> */}
				{/* <Divider className="animateOnScroll fademe" /> */}
				{/* <Section titlePrefix="Our" title="Values">
					<Row
						sx={{
							alignItems: "center",
							flexDirection: { xs: "row-reverse", md: "unset" },
							justifyContent: "center",
							flexWrap: { xs: "wrap", md: "nowrap" },
							margin: "16px 0",

							"& > *": {
								margin: { md: "20px !important", xs: "10px 0" },
								flexGrow: 1,
								maxWidth: { md: "50%", xs: "unset" },
								// maxHeight: "400px",
							},
						}}
					>
						<Paper
							className="animateOnScroll fademe"
							sx={{
								aspectRatio: "5/3",
								width: "100%",
								objectFit: "cover",
							}}
							component={"img"}
							src="https://www.arlingtontx.com/wp-content/uploads/2020/03/DOCTORS-edit.jpeg"
						></Paper>

						<Column
							sx={{
								gap: "20px",
							}}
						>
							<Typography
								className="animateOnScroll fademe"
								variant="h6"
								sx={{
									textAlign: "left",
									whiteSpace: "break-spaces",
								}}
							>
								Accountability, Commitment, Devotion, Ethics,
								Honesty, Humility, Integrity, Trustworthiness
							</Typography>
						</Column>
					</Row>
				</Section>
			*/}
				<Divider className="animateOnScroll fademe" />

				<Section titlePrefix="Meet" title="The Team">
					<Column sx={{ gap: "20px", mt: "30px" }}>
						<Row
							sx={{
								justifyContent: "center",
								flexWrap: "wrap",
								gap: "10px",
							}}
						>
							<TeamMember
								firstName="Muhab"
								lastName="Al-Bustami"
								position="Founder & CEO"
								email=""
								imageSrc="/assets/images/employee.jpg"
							/>
						</Row>

						<Row
							sx={{
								justifyContent: "space-between",
								flexWrap: "wrap",
								gap: "20px",
							}}
						>
							<TeamMember
								firstName="Ma’moun"
								lastName="Al-Wawi"
								position="Service & Equipment Sales Manager"
								degree="Electronics Engineering"
								email="M.alwawi@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
							<TeamMember
								firstName="Zeyad"
								lastName="Al-Bustami"
								position="Business Associate"
								degree="MPharm"
								email="Zeyad@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
							<TeamMember
								firstName="Enas"
								lastName="Al-Sousi"
								position="Logistics & Accounts Manager"
								degree="BBA / MSc Business & Management"
								email="E.alsousi@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
						</Row>

						<Row
							sx={{
								justifyContent: "space-between",
								flexWrap: "wrap",
								gap: "20px",
							}}
						>
							<TeamMember
								firstName="Ahmad"
								lastName="AbuAwad"
								position="Service Specialist"
								degree="Biomedical Systems Engineering"
								email="A.abuawad@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
							<TeamMember
								firstName="Malak"
								lastName="Hajahjeh"
								position="Sales & Application Specialist"
								degree="Biotechnology & Genetic Engineering"
								email="M.hajahjeh@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
							<TeamMember
								firstName="Maha"
								lastName="Dweik"
								position="Tenders & Orders Manager"
								degree="BBA"
								email="M.dweik@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
						</Row>
						<Row
							sx={{
								justifyContent: "center",
								flexWrap: "wrap",
								gap: "10px",
							}}
						>
							<TeamMember
								firstName="Ajam"
								lastName="Jayousi"
								position="Sales & Application Specialist"
								degree="Biotechnology & Genetic Engineering"
								email="A.jayousi@fursan-med.com"
								imageSrc="/assets/images/employee.jpg"
							/>
						</Row>
					</Column>
				</Section>
			</Container>
		</Box>
	);
}
