import { Box, Typography } from "@mui/material";
import "./style.scss";

export function IraqMap() {
	return (
		<Box
			className="CountryMap animateOnScroll"
			// sx={{
			// 	"--upto": 80,
			// }}
		>
			<Box
				component={"svg"}
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 800 600"
			>
				<g id="Iraq">
					<path
						className="st0"
						d="M459.644,45.923l-0.737,1.959l0.008,0.668l0.432,1.202l0.32,0.455l0.384,0.178l0.407,0.126l0.392,0.288
						l0.21,0.443l0.275,1.036l0.25,0.337l0.505,0.109l1.071-0.334l0.524-0.025l0.379,0.214l0.617,0.674l1.183,0.625l0.359,0.611
						l-0.09,0.737l-0.594,0.751l-0.303,1.811l0.308,1.202l0.499,1.107l0.258,1.522l-0.292,1.634l-0.749,1.213l-1.926,2.369l-0.583,1.674
						l0.426,1.18l1.082,0.725l1.413,0.322l1.682-0.094l0.785,0.133l0.678,0.595l0.499,0.599l0.561,0.402l0.634,0.203l0.707,0.021
						l0.908,0.497l0.802,1.092l1.318,2.434l0.578,1.458l-0.087,1.265l-1.054,2.446l-0.58,2.308l-0.05,1.424l-0.163,0.129l-0.673,0.265
						l-0.241,0.164l-0.109,1.075l0.788,1.015l0.196,0.169l0.858,0.741l0.695,0.757l0.05,0.956l0.348,0.554l0.465,0.45l0.435,0.648
						l0.261,0.875l0.09,0.666l0.202,0.613l0.622,0.714l1.469,0.693l1.581-0.125l3.039-1.216l1.374,0.101l0.953,1.209l0.583,1.689
						l0.264,1.56l0.137,2.903l0.376,1.172l1.018,1.304l-1.312,0.852l0.309,0.81l1.009,0.793l0.791,0.786l0.252,1.289l-0.135,3.102
						l0.275,1.413l1.682,2.194l0.69,1.357l-0.224,1.346l-0.667,1.065l-0.667,1.38l-0.32,1.383l0.353,1.081v0.01l0.006,0.007l0.011,0.003
						l0.953,0.984l1.166,0.163l1.206-0.409l1.071-0.738l0.017-0.003l0.006-0.004l0.011-0.01l0.965-0.27l1.8-0.898l1.419-0.024
						l1.202,0.441l2.092,0.768l2.005,1.861l0.701,0.443l1.475,0.281l1.618,1.489l2.515,3.573l1.385,3.452l0.667,0.882l0.976,0.277
						l2.047-0.619l0.606,0.124l0.883,0.605l1.116-0.079l1.049-0.522l1.315-1.131l0.552,0.024l0.558,0.18l0.561,0.059l1.744-0.605
						l3.454,0.159l1.088-0.356l1.049-0.602l0.875-0.332l0.897,0.017l1.671,0.64l0.395,0.066l0.261,0.239l0.269,1.366l0.233,0.463
						l0.331,0.37l0.443,0.367l1.094,0.47l1.164-0.045l2.305-0.505l1.029-0.38l0.928-0.65l0.97-0.481l1.172,0.117l0.903,0.647
						l0.404,0.895l-0.109,1.047l-0.634,1.092l-0.861,0.778l-1.649,1.054l-0.74,1.095l-0.847,0.815l-1.133,0.131l-2.131-0.121l-2.72,1.45
						l-1.116,0.259l-3.796-0.117l-1.116,0.39l-0.69,0.766l-0.429,1.059l-0.191,1.17l-0.003,1.1l0.527,2.566l-0.151,0.921l-1.657-0.507
						h-0.261l-0.247,0.069l0.471,1.348l0.608,1.268l0.21,0.279l0.079,0.283l-0.073,0.272l-0.216,0.265l-0.429,0.331l-0.286,0.438
						l-0.135,0.537l0.023,0.616l0.706,1.921l3.471,5.577l3,2.689l1.295,1.505l0.393,1.958l-0.115,0.271l-0.284,0.67l-0.673,0.807
						l-0.482,0.845l0.146,1.047l0.757,0.529l2.103-0.02l0.819,0.69l-0.205,1.747l-1.175,2.014l-0.729,1.872l1.121,1.313l-0.432,0.802
						l-0.583,0.315l-0.673,0.134l-1.38,0.459l-0.97-0.257l-0.547,0.058l-1.455,0.881l-0.796,0.747l-0.861,0.223l-1.626-0.706
						l-0.698,0.055l-0.561-0.233l-1.144-0.743l-0.802-0.278l-0.379,0.154l-0.749,1.24l-0.056,0.312l0.151,0.815l-0.112,0.312
						l-0.325,0.168l-0.763,0.075l-0.356,0.12l-0.575,0.572l-0.264,0.764l-0.011,0.876l0.157,0.904v0.01l0.502,1.714l-0.143,1.245
						l-0.46,1.207l-0.415,1.621l-0.824,1.101l-1.464-0.509l-0.642-0.344l-0.973-0.518l-1.256,0.062l-0.359,1.272l0.107,1.678
						l-0.275,1.613l-1.503,1.049l-1.545,0.164l-0.687,0.287l-0.533,0.751l-0.171,0.792l0.126,1.335l-0.168,0.758l-1.508,1.737
						l-0.415,1.065l0.807,0.921l0.449,0.256l0.488,0.382l0.398,0.477l0.191,0.539l0.168,0.702l0.266,0.399l0.278,0.324l0.224,0.46
						l0.171,1.247l0.025,1.24l0.174,1.117l0.606,0.855l0.16,0.429l-0.014,0.705l-0.146,0.701l-0.221,0.426l-0.322,0.068l-1.194-0.422
						l-3.729-0.296l-1.677-0.405l-1.862-0.984l-2.114-0.623l-0.224,1.808l0.452,2.665l-0.076,1.95l-0.258,0.522l-0.308,0.476
						l-0.353,0.413l-0.393,0.361l-2.849,0.641l-0.527,0.865l1.677,5.41l0.695,1.421l1.004,0.744l1.082-0.871l1.211-0.007l1.122,0.717
						l0.833,1.29l0.297,1.49l-0.109,1.62l-0.723,3.158l-0.269,0.592l-0.763,1.241l-0.109,0.607l0.261,0.747l0.398,0.569l0.311,0.564
						v0.73l-0.552,0.74l-2.193,1.803l-0.796,0.855l-1.323,1.164l-0.611,1.454l-0.457,1.671l-0.827,1.818v0.007h-0.011l-0.449,0.465
						l-0.527,0.362l-1.161,0.527l1.122,1.577l1.231,0.698l1.41,0.091l1.604-0.248l0.135,0.02l0.118,0.054l0.101,0.091l4.828,8.804
						l0.729,0.811l0.836,0.703l0.712,0.786l0.39,1.084l0.233,1.32l0.376,1.153l0.544,1.06l0.74,1.058l0.824,0.825l2.557,1.843
						l0.191,0.504l0.182,0.481l-0.014,2.316l0.37,0.454l0.948-0.237l0.892-0.596l1.579-1.525l0.99-0.423l2.636-0.04l1.172-0.293
						l-0.213,2.002l0.959,0.98l1.15,0.793l0.381,1.44l-0.667,0.981l-2.574,1.577l-0.847,1.12l0.931,0.797l1.609,0.378l1.643-0.208
						l1.004-0.96l0.269-0.151l0.272-0.052l0.278,0.054l0.066,0.037l0.203,0.114l0.171,0.096l0.166,0.128l1.822,2.286l0.617,1.122
						l0.437,1.228l0.166,1.32l0.031,1.085l0.28,0.947l1.817,1.759l2.159,3.062l1.727,1.402l0.757,0.771l0.482,1.095l0.011,0.667
						l-0.328,1.245l-0.036,0.531l0.241,0.603l0.628,0.399l0.034,0.586l-0.387,0.832l-0.746,0.913l-1.464,1.457l-1.144,0.867l-0.88,0.176
						l-0.908-0.07l-1.194,0.107l-1.091,0.611l-0.053,0.788l0.757,0.651l2.383,0.274l1.021,0.435l0.782,0.701l0.311,0.887l-0.362,1.099
						l-0.805,0.838l-0.903,0.734l-0.667,0.791v0.01l1.155,2.589l3.177,0.386l6.396-0.72l5.762,1.793l5.409,2.583l1.52,1.536l5.062,2.937
						l0.207,0.12l2.493,2.021l3.555,2.153l2.249,2.568l16.335,14.281h0.011l0.003,0.005l1.733,1.276l1.651,0.869l1.71,0.377l1.912-0.206
						l0.959-0.364l2.484-1.378l0.763,0.05l3.073,1.059l1.161,0.613l0.693,0.804l0.608,0.992l0.911,1.169l1.45,1.111l0.303,0.47
						l0.084,0.742l-0.196,0.422l-0.303,0.355l-0.477,1.192l-0.219,0.244l-0.008,0.319l0.362,0.868l0.25,0.368l1.618,1.546l0.255,0.353
						l0.205,0.357l0.959,1.335l0.348,0.366l0.527,0.26l0.292,0.816l1.057,0.809l0.283,0.83l-0.084,0.895l-0.289,0.623l-0.323,0.563
						l-0.213,0.696l-0.112,0.894l0.05,0.507l0.37,0.288l0.85,0.245l0.698,0.444l0.437,0.655l0.482,0.55l0.819,0.103l1.049,0.437
						l0.118,0.17l0.802,1.157l1.237,2.789l0.833,0.956l1.896,0.929l0.536,1.134v0.01l0.255,1.127l1.94,3.49l0.37,0.512l0.981,0.644
						l0.415,0.386l0.303,0.578l0.28,1.119l0.193,0.482l0.872,0.974l2.139,1.614l0.743,1.142l0.151,1.356l-0.323,1.444l-8.269,22.547
						l-0.135,10.882l-0.19,15.326l17.83,0.003l0.58,0.353l0.174,0.811l-0.185,30.406l0.084,1.933l5.742,0.823l0.606,0.208l0.561,0.351
						l0.909,0.982l0.695,1.235l0.488,1.372l0.446,2.769l0.238,0.601l0.435,0.461l0.603,0.27l0.639,0.098l0.656-0.026l1.985-0.335
						l0.684,0.032l0.656,0.224l0.508,0.435l1.102,1.844l1.744,1.99l2.109,1.934l0.356,0.532l0.278,0.639l0.104,0.678l-0.14,0.668
						l-0.395,0.542l-0.521,0.47l-0.426,0.522l-0.118,0.697l0.107,0.434l0.679,1.456l1.082,1.228l0.309,0.645l0.149,0.107l0.99,3.117
						l0.112,0.816l0.482,1.221l0.252,0.411l0.353,0.359l0.751,0.596l0.808,0.492l1.68,0.479l0.381,0.174l-0.013,0.036v0.285l1.311-0.018
						l0.234,0.619l-0.671,0.744l-1.431,0.364l-5.895-0.596l-4.146-1.44l-1.947-1.448l-1.894-0.591l-3.007-1.912l-2.358-0.819
						l-2.53-0.467l-2.323-0.041l-2.066,0.551l-3.758,2.055l-0.384-1.647l-0.186-1.898l-0.371-1.737l-0.914-1.176l0.667,2.041
						l0.075,0.765l-0.133,0.811l-0.234,0.76l-0.044,0.673l0.411,0.556l-0.026,1.479l-11.751-5.924l-3.107-0.606H603.41l-3.123,0.382
						l-8.742,3.629l-2.843,1.936l-1.856,2.658l-4.615,11.811l-1.985,3.717l-0.28,0.897l-0.208,1.888l-0.135,0.629l-1.06,2.344
						l-4.054,6.733l-1.609,4.235l-0.807,1.22l-3.477,3.826l-3.398,5.734l-8.16,9.151l-1.575,1.765l-2.378,0.507l-2.372,0.507
						l-0.953,0.203l-1.419,0.302L545.987,570l-5.714-0.516l-4.136-0.374l-4.136-0.379l-4.127-0.374l-4.138-0.375l-5.557-0.504
						l-5.563-0.504l-5.563-0.507l-5.56-0.502l-5.56-0.502l-5.563-0.507l-5.557-0.503l-5.563-0.507l-4.321-0.39l-4.315-0.395
						l-4.318-0.389l-4.329-0.397l-4.963-0.448l-0.356-0.135l-0.339-0.138l-0.348-0.138l-0.348-0.132l-4.175-3.372l-5.162-4.184
						l-5.179-4.184l-5.173-4.19l-5.17-4.189l-6.213-5.042l-6.213-5.044l-6.216-5.047l-3.584-2.913l-2.632-2.141l-6.213-5.058
						l-6.219-5.059l-6.219-5.063l-6.213-5.067l-4.783-3.903l-4.781-3.905l-4.778-3.905l-4.784-3.913l-5.737-4.691l-0.006-0.003
						l-0.006-0.003l-0.006-0.003l-4.085-3.551l-1.057-0.919l-5.142-4.479l-5.137-4.476l-5.142-4.483l-4.587-3.976l-4.581-3.979
						l-4.582-3.982l-4.593-3.984l-3.595-3.132l-4.839-2.878l-4.755-2.834l-4.767-2.835l-4.755-2.837l-4.755-2.835l-4.767-2.839
						l-4.761-2.841l-4.755-2.842l-4.755-2.843l-4.767-2.848l-4.755-2.846l-4.761-2.844l-4.761-2.849l-4.755-2.85l-4.761-2.855
						l-4.767-2.846l-4.755-2.858l-5.58-3.348l-3.023-1.77l-2.922-1.146l-18.5-3.576l-0.011-0.003l-0.006-0.003h-0.011l-4.352-0.744
						l-10.722-1.842l-10.728-1.846l-10.728-1.846l-10.733-1.844l-0.151,0.05l-0.151,0.053l-0.157,0.053l-0.157,0.05l-0.011-0.493
						l6.527-5.578l1.385-2.03l-1.133-4.328L160.691,363l-1.116-0.654l-10.279,2.849l-0.55-0.312l-0.404-0.962l-2.647-9.239l-0.073-0.105
						l-0.022-0.08l0.022-0.047l0.073-0.023l4.189-1.321l-3.645-13.46l-2.563-9.471l-2.473-9.179l-1.879-6.886l-2.254-8.317l-2.524-9.261
						l6-3.602l6-3.601l6-3.608l6-3.607l6-3.61l6-3.61l6-3.614l6-3.611l5.995-3.621l6.006-3.62l5.995-3.622l6-3.626l3.888-2.352
						l2.113-1.278l6-3.629l6-3.631l6-3.639l7.957-4.828l13.324-3.587l1.587-1.038l1.234-1.754l1.952-4.321v-0.01l9.315-18.077
						l0.465-1.628l0.123-1.731l-0.46-11.56l-0.31-10.93l-0.044-1.542l0.533-4.043l2.266-8.212l0.477-6.496l0.499-2.004h0.006h0.006
						v-0.01l2.56-3.862l1.831-2.765l0.858-2.018l0.297-2.091l-0.23-9.19l-0.258-2.195l-0.589-2.148l-4.195-9.151v-0.007l-0.006,0.003
						l-1.391-3.269l-0.213-1.162l-0.006-1.12l1.75-16.873l0.443-1.807l4.789-9.381v-0.007l0.006-0.004v-0.007l1.088-1.5l1.598-0.743
						l3.522-0.59l16.829-3.592l1.486-0.705l1.436-1.226l7.312-7.831l10.845-11.637l5.597-6.019l0.017-0.003v-0.007l3.471-3.315
						l1.679-1.298l0.021-0.016l-0.017-0.987l-0.308-0.761l-0.409-0.694l-0.348-0.805l2.422-0.283l3.107-1.032l4.671-0.788l0.869-0.39
						l0.185-0.369l0.667-1.851l6.802-9.96l0.207-0.538l0.067-0.473l0.112-0.48l0.336-0.554l0.353-0.247l2.681-1.092L351.905,30
						l0.65,0.079l0.471,0.358l0.236,1.176l0.465,0.344l4.486,1.495l2.187,0.324l2.31-0.797l1.385-1.053l2.08-0.887l2.181-0.586
						l1.688-0.157l0.931,0.265l7.13,3.86l0.376,0.138l0.451,0.063l0.482-0.095l0.508-0.518l0.477-0.22l1.004-0.153l0.65,0.136
						l1.43,1.113l0.734,0.572l2.21,1.115l2.523,2.079l0.886,0.363l0.695-0.095l1.34-0.514l0.673-0.109l0.701,0.109l0.443,0.243
						l0.992,0.752l1.374,0.562l1.307,0.169l5.563-0.384l1.416,0.134l1.287,0.327l0.538,0.373l1.167,1.144l0.398,0.264l0.684-0.183
						l0.976-1.003l2.91-0.532l1.657-1.92l1.607-2.345l1.985-1.708l2.453-0.391l1.814,0.308l1.032,0.175l5.232,2.182l1.217,0.795
						l0.883,0.918l0.642,1.178l0.463,1.563l0.272,1.493l0.059,0.85l-0.07,0.665l-0.446,0.797l-0.538,0.248l-0.62,0.116l-0.695,0.408
						l-0.908,1.185l-0.465,1.38l-0.034,1.45l0.404,1.406l2.187,4.949l0.353,0.711l0.488,0.4l2.254,0.584l0.673-0.053l0.51-0.495
						l0.502-1.137h0.006l0.008-0.003v-0.007l0.841-1.45l0.191-1.066l0.432-0.768l4.61-1.518l1.43-0.787l1.346-1.065l1.312-1.675
						l1.952-1.841l2.108-1.548l1.738-0.813l1.012-0.046l5.689,0.802l1.088,0.551L459.644,45.923z"
					></path>
				</g>
			</Box>
			<Box
				className="MapText"
				// sx={{
				// 	"--upto": 80,
				// }}
			>
				<Typography
					// className="Counter"
					variant="h1"
					sx={{
						textAlign: "center",
						fontWeight: "bold",
						lineHeight: 0.5,
					}}
				></Typography>
				<Typography
					variant="h1"
					sx={{ textAlign: "center", fontWeight: "bold" }}
				>
					Iraq
				</Typography>
			</Box>
		</Box>
	);
}
