import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Column } from "../Section/column";
import { Row } from "../Section/row";
import { IraqMap } from "./IraqMap";
import { JordanMap } from "./JordanMap";

export function Countries() {
	const navigate = useNavigate();
	return (
		<Column>
			<Row className="animateOnScroll fademe">
				<Typography
					variant="h6"
					sx={{ color: "unset", textAlign: "center" }}
				>
					We are firmly established in Jordan and are consistently
					expanding our operations to meet the growing needs of the
					healthcare sector. If you are interested in partnering with
					a leading provider in the industry, we welcome the
					opportunity to collaborate with you.
				</Typography>
			</Row>
			<Row
				sx={{ flexWrap: "wrap", justifyContent: "space-around" }}
				className="animateOnScroll fademe"
			>
				{/* <Box
					className="CountryMap animateOnScroll"
					sx={{ minWidth: "unset" }}
				/> */}

				<JordanMap />
				{/* <Box
					className="CountryMap animateOnScroll"
					sx={{ minWidth: "unset" }}
				/> */}

				<IraqMap />
				{/* <></> */}
			</Row>

			<Row
				sx={{ justifyContent: "center" }}
				className="animateOnScroll fademe"
			>
				<Button
					variant="contained"
					onClick={() => navigate("/clients")}
				>
					Our Clients
				</Button>
			</Row>
		</Column>
	);
}
