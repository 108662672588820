import { Box, Typography } from "@mui/material";
import "./style.scss";

export function JordanMap() {
	return (
		<Box className="CountryMap animateOnScroll">
			<Box
				component={"svg"}
				version="1.1"
				x="0px"
				y="0px"
				viewBox="0 0 800 600"
			>
				<g id="Jordan">
					<path
						className="st0"
						d="M227.932,159.65l-0.456-0.81l-0.536-0.571l-0.262-0.425l-0.49-1.442l1.072-0.209l0.467-0.365l-0.103-0.527
						l-0.684-0.686v-0.811h0.821l-0.171-0.611l-0.182-0.088l-0.228,0.044l-0.239-0.23l1.505-0.979l-0.684-0.892l0.753-0.338l0.695-0.473
						v-0.976l-0.49-0.101l-0.296-0.176l-0.251-0.243l-0.411-0.291l0.205-0.875l0.411-0.892l0.661-0.693l0.992-0.297v-0.818l-1.038-1.085
						l-0.228-0.554l-0.787-1.166l-0.456-0.02l-0.581,0.068v-0.886h1.505l-0.308-0.842l-0.376-2.739l0.684,0.896l0.502-1.403l0.262-0.467
						l0.148,0.477l0.057,0.308l0.16-0.074l0.456-0.71l-0.616-1.62l-0.342-3.376l-0.627-1.178v-0.978h0.764v-0.816l-0.935-0.555
						l-0.445-0.944l0.057-1.076l0.24-0.433l0.787-0.335l0.98-0.873l1.665-2.218l2.03-1.466l0.012,0.001l2.61,0.313l1.767-0.921
						l3.66-3.286l6.066-3.842l1.904-0.491l0.017-0.008l0.69-0.344l0.65-0.142l0.57,0.102l0.467,0.363l-0.011,0.02l1.015,1.305
						l11.858,2.772l1.049,0.617l1.904,1.941l0.547,0.183l1.425-0.017l0.422,0.227l0.171,0.874l-0.148,0.85l-0.354,0.535l-0.41-0.061
						l1.003,1.253l0.969,0.917l1.152,0.403l1.596-0.295l2.577,0.2l0.513,1.49l-0.331,2.231l0.08,2.46l1.038,2.192l4.971,7.579
						l0.616,1.525l0.023,0.561l0.399,0.095l1.328,0.043l1.58,0.051l4.082-0.554l0.695,0.074l1.14,0.446l0.661,0.122l0.547-0.264
						l1.254-1.139l0.57-0.183l1.197,0.659l3.591,3.623l7.115,4.923l9.691,9.215l1.63,0.928l2.178,0.665l6.214,0.631l1.79,1.124
						l4.027,0.493l15.094,1.848l3.979,3.029l1.915-1.133l2.394,0.071l7.046,1.862l1.551,0.064l1.414-0.489l17.73-12.179l16.886-11.046
						h0.023l12.211-7.833l18.881-12.144l8.758-5.625l10.157-6.527l18.904-12.162l18.904-12.192l14.047-9.067l19.212-12.109l9.362-5.91
						l23.589-14.905L582.447,30l5.131,18.829l4.583,16.91l3.82,14l5.028,18.663l5.211,19.257l7.411,27.366l-8.517,2.686l-0.148,0.047
						l-0.046,0.095l0.046,0.162l0.148,0.213l5.382,18.784l0.821,1.956l1.117,0.634l20.899-5.792l2.269,1.329l1.63,4.011l2.303,8.799
						l-2.816,4.128l-13.272,11.342l0.023,1.003l-1.117,0.363l-2.144,1.622l-13.101,12.491l-3.82,1.62v0.02l-0.011-0.007l-12.531,3.649
						l-24.81,7.208l-24.787,7.211l-24.799,7.2l-20.834,6.042l-3.976,1.153l-20.858,6.271l-6.472,1.945l-27.33,8.217l-27.33,8.205
						l-23.096,6.929l-4.234,1.27l14.355,15.634l14.355,15.621l0.215,0.233l14.14,15.354l14.355,15.581l0.342,0.406l0.353,0.412
						l0.342,0.412l0.353,0.412l12.097,14.31l12.109,14.287l12.12,14.264l12.097,14.254l5.461,6.426l0.034,0.086l-0.046,0.053
						l-0.103,0.04l-0.741,0.456l-8.072,4.538l-13.329,7.504l-11.983,6.753l-2.554,2.14l-1.437,2.311l-3.238,7.929l-3.968,9.671
						l-3.648,8.886l-4.903,11.994l-2.371,2.12l-12.998,2.705l-14.765,3.08l-15.746,3.289l-15.871,3.315l-9.817,2.038l-9.566,1.999
						l-3.033,1.525l-2.634,2.846l-6.1,10.392l-5.074,8.628l-6.875,11.685l-7.081,12.021l-8.506,7.059l-12.827,10.643l-11.664,9.677
						l-11.972,9.905l-2.828,1.156L278.18,570l-11.47-1.989l-12.713-2.198l-6.285-1.094l-13.041-2.27l-16.373-2.83l-15.335-2.667
						l-17.16-2.975l-13.101-2.282l-12.238-2.15l1.437-1.023l0.727-11.532l0.781-3.32l2.397-5.188l-0.108-2.017l-2.289-2.317h-1.508
						l-0.09-0.413v-0.341l-0.153-0.181l-0.512,0.067l0.473-3.453l0.787-2.78l1.471-2.397l1.049-3.168l0.581-7.136l0.821-3.167
						l5.119-13.883l0.467-2.583l0.103-7.733l0.81-4.322l0.433-1.099l0.547-0.987l0.41-1.125l0.068-1.534l1.277-5.023l4.72-7.101
						l1.79-4.29v-4.021l-2.258-7.809l0.046-3.649l0.787-2.198l1.038-6.655l0.661-1.598l0.741-1.314l0.604-1.374l0.24-1.777l-0.308-1.817
						l-1.585-2.591l-0.547-1.315v-3.074l1.915-5.204l0.525-3.05l4.766-15.683l6.453-13.17l0.007-0.024l0.848-3.047l0.502-3.244
						l0.376-1.447l0.73-1.553l0.855-1.009l1.87-1.686l0.639-1.221l0.376-2.842l-0.057-2.875l0.297-2.909l1.402-2.91l1.344-1.302
						l2.977-2.885l1.231-2.333l-0.011-4.036l0.707-3.771l5.176-10.276l0.524-3.669l-0.775-3.524l-1.642-3.218l-1.174-2.592l-1.049-3.313
						l-0.604-3.534l1.38-3.142l1.551-2.708l0.182-2.789l-0.821-0.934l2.052-3.718l1.961-5.195l0.445-2.932l0.027-1.295l0.156-7.514
						l-0.046-0.04l0.068,0.047l0.046-0.04l-0.011-0.12l-0.034-0.207l0.604-9.598l1.756-9.398l2.465-5.734l2.769-6.446l3.512-3.929
						l-2.326-7.002v-0.966l1.186-1.617l-2.691-10.399l0.308-1.055l0.684-0.383l0.764-0.222l0.502-0.598l0.08-1.035l-0.65-1.438
						l-0.182-1.082l-0.068-1.774l-0.239-1.385l-1.197-3.052l-0.205-6.005l0.604-2.247l1.859-1.527l-1.163-1.615l0.068-1.508l1.847-3.177
						l-0.582-0.835v-0.707l0.502-0.586l0.901-0.465v-0.97l-0.467-1.546l0.484-2.082l1.431-6.158h-1.448l0.182-0.725l0.296-0.479
						l0.422-0.35l0.547-0.314l-0.878-1.523l-0.41-2.485l0.034-2.489l0.49-1.521l-1.003-1.939l0.091-3.917l-0.593-1.272v-0.898h0.821
						v0.898h0.684l-0.182-1.188L227.932,159.65z"
					/>
				</g>
			</Box>
			<Box
				className="MapText"
				sx={{
					"--upto": 30,
				}}
			>
				<Typography
					// className="Counter"
					variant="h1"
					sx={{
						textAlign: "center",
						fontWeight: "bold",
						lineHeight: 0.5,
					}}
				></Typography>
				<Typography
					variant="h1"
					sx={{ textAlign: "center", fontWeight: "bold" }}
				>
					Jordan
				</Typography>
			</Box>
		</Box>
	);
}
